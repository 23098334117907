import { DateTime } from 'luxon'
import { MeasureCollection } from './MeasureCollection'
import { MeasureKind } from './MeasureKind'
import { Station } from './Station'

export class TimeSeries {
    private period: [DateTime, DateTime]

    constructor(
        readonly id: string,
        readonly station: Station,
        readonly measureKind: MeasureKind,
        readonly history: MeasureCollection,
        readonly forecasts: MeasureCollection
    ) {
        const now = DateTime.now().toUTC().set({ second: 0, millisecond: 0 })
        const first = history.length > 0 ? history : forecasts
        const last = forecasts.length > 0 ? forecasts : history
        this.period = [first.getFirstTimestampOr(now), last.getLastTimestampOr(now)]
    }

    getStart(zone = 'UTC'): DateTime {
        return this.period[0].setZone(zone)
    }

    getEnd(zone = 'UTC'): DateTime {
        return this.period[1].setZone(zone)
    }

    getTimeStamps(zone = 'UTC'): DateTime[] {
        const allTimestamps = [...this.history, ...this.forecasts]
        return allTimestamps.map((dt) => dt.setZone(zone))
    }

    get hasHistory(): boolean {
        return this.history.length > 0
    }

    get hasForecasts(): boolean {
        return this.forecasts.length > 0
    }
}
