import { FeatureCollection, GeoJsonProperties, Point } from 'geojson'
import { AnyLayer, FillLayer } from 'mapbox-gl'

import { City } from '@/model/City'
import { TimeStep } from '@/model/TimeStep'
import { FloodDepth } from '@/model/Representation'
import { IMapService } from '@/services/map/IMapService'
import { MapBoxGL } from '../MapBoxGL'
import { IController } from './IController'
import { IControllerParent } from './IControllerParent'
import { BaseRepresentationController } from './BaseRepresentationController'

const DEFAULT_SOURCE = 'floodDepth'

export class FloodDepthController extends BaseRepresentationController implements IController {
    constructor(parent: IControllerParent, mapBoxGL: MapBoxGL, mapService: IMapService) {
        super(parent, mapBoxGL, mapService, FloodDepth)
    }

    async load(city: City, timeStep?: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        let result = true

        if (!timeStep) {
            return Promise.resolve(false)
        }

        const requestedTimestamp = timeStep.timestamp.toISO()

        let geoJson = (await this.mapService?.fetchFloodDepth(city, timeStep, scale)) as FeatureCollection
        if (!geoJson) {
            result = false
            geoJson = this.emptyGeoJson
        }

        const currentTimeStep = this.parent.getCurrentTimeStep()
        if (currentTimeStep && requestedTimestamp !== currentTimeStep.timestamp.toISO()) {
            return result
        }

        this.mapBoxGL.showOnlyLayers([
            ...this.getLayers().map((layer) => layer.id),
            `${this.mapBoxGL.applicationPrefix}-stationstext`,
            `${this.mapBoxGL.applicationPrefix}-selectedstations`,
            `${this.mapBoxGL.applicationPrefix}-unselectedstations`,
            `${this.mapBoxGL.applicationPrefix}-cityContours*`
        ])

        this.mapBoxGL.updateSource(DEFAULT_SOURCE, geoJson)
        if (zoomOnSource) {
            this.mapBoxGL.zoom(geoJson)
        }

        return result
    }

    buildPopupText(properties: GeoJsonProperties): string | null {
        if (!properties) {
            return null
        }

        const value = properties[this.representation.dynamicPropertyName]
        return `
            <b>${this.translationService.translate(this.representation.legend.titleTranslationKey)}</b>
            <br />
            ${this.representation.format(value)}
            ${this.translationService.translate(this.representation.unitTranslationKey)}
        `
    }

    getSources(): string[] {
        return [DEFAULT_SOURCE]
    }

    getLayers(): AnyLayer[] {
        const propertyName = this.representation.dynamicPropertyName
        return [
            {
                id: `${this.mapBoxGL.applicationPrefix}-floodDepth`,
                type: 'fill',
                source: DEFAULT_SOURCE,
                minzoom: 7,
                paint: {
                    'fill-opacity': 0.7,
                    'fill-color': [
                        'case',
                        ['<', ['get', propertyName], this.representation.legend.thresholds[0].value],
                        'transparent',
                        [
                            'interpolate',
                            ['linear'],
                            ['get', propertyName],
                            ...this.unpackThresholds(this.representation.legend)
                        ]
                    ]
                }
            } as FillLayer
        ]
    }

    getLayersWithPopup(): AnyLayer[] {
        return [this.layers[0]]
    }

    getCursors(): Map<string, string> {
        return new Map([[`${this.mapBoxGL.applicationPrefix}-floodDepth`, 'pointer']])
    }
}
