import axios from 'axios'
import { FeatureCollection, GeoJsonProperties } from 'geojson'
import { AnyLayer, FillLayer } from 'mapbox-gl'

import { City } from '@/model/City'
import { FloodRisk } from '@/model/Representation'
import { TimeStep } from '@/model/TimeStep'
import { IMapService } from '@/services/map/IMapService'
import { MapBoxGL } from '../MapBoxGL'
import { IController } from './IController'
import { IControllerParent } from './IControllerParent'
import { BaseRepresentationController } from './BaseRepresentationController'

const DEFAULT_SOURCE = 'floodRisk'

export class FloodRiskController extends BaseRepresentationController implements IController {
    private city: City | null = null

    constructor(parent: IControllerParent, mapBoxGL: MapBoxGL, mapService: IMapService) {
        super(parent, mapBoxGL, mapService, FloodRisk)
    }

    async load(city: City, timeStep?: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        if (!timeStep) {
            return Promise.resolve(false)
        }

        let hasData = false

        const source = await this.collectSource(city)
        for (const feature of source.features) {
            if (!feature.properties) {
                continue
            }

            const areaName = feature.properties['Administrative Area']
            const index = city.getAdministrativeAreas().getIndexOf(areaName)
            if (index >= 0) {
                const value = timeStep.getAdministrativeAreaValueAt(index)
                feature.properties[this.representation.dynamicPropertyName] = value
                hasData = hasData || value >= 0
            }
        }

        this.mapBoxGL?.updateSource(DEFAULT_SOURCE, source)
        this.mapBoxGL.showOnlyLayers([
            ...this.getLayers().map((layer) => layer.id),
            `${this.mapBoxGL.applicationPrefix}-cityContours-*`
        ])

        if (zoomOnSource) {
            this.mapBoxGL.zoom(source)
        }

        this.city = city

        return hasData
    }

    async collectSource(city: City): Promise<FeatureCollection> {
        let source = city.getAdministrativeAreas().getContours()
        if (source) {
            return Promise.resolve(source as FeatureCollection)
        }

        const url = city.getAdministrativeAreas().getContoursUrl()
        if (!url) {
            return Promise.resolve(this.mapBoxGL.emptyGeoJson)
        }

        source = (await axios.get(url)).data as FeatureCollection
        for (const feature of source.features) {
            if (!feature.properties) {
                continue
            }
            feature['id'] = feature.properties['Administrative Area']
        }
        city.getAdministrativeAreas().setContours(source)
        return source
    }

    buildPopupText(properties: GeoJsonProperties): string | null {
        if (!properties) {
            return null
        }

        const administrativeArea = properties['Administrative Area']
        if (!administrativeArea) {
            return null
        }

        const risk = properties[this.representation.dynamicPropertyName]
        return `
            <b>${administrativeArea}</b>
            <br /> 
            ${this.translationService.translate(this.representation.format(risk))}
        `
    }

    getSources(): string[] {
        return [DEFAULT_SOURCE]
    }

    getLayers(): AnyLayer[] {
        const propertyName = this.representation.dynamicPropertyName
        const legend = this.representation.legend
        return [
            {
                id: `${this.mapBoxGL.applicationPrefix}-administrativeAreasContent`,
                type: 'fill',
                source: DEFAULT_SOURCE,
                minzoom: 7,
                paint: {
                    'fill-opacity': 0.7,
                    'fill-color': [
                        'case',
                        ['<', ['get', propertyName], legend.thresholds[0].value],
                        legend.thresholds[0].color,
                        [
                            'step',
                            ['get', propertyName],
                            legend.thresholds[0].color,
                            ...this.unpackThresholds(this.representation.legend)
                        ]
                    ]
                }
            } as FillLayer
        ]
    }

    getLayersWithPopup(): AnyLayer[] {
        return [this.layers[0]]
    }

    getCursors(): Map<string, string> {
        return new Map([[`${this.mapBoxGL.applicationPrefix}-administrativeAreasContent`, 'pointer']])
    }
}
