import { FloodRisk, IRepresentation, ReturnPeriod } from '@/model/Representation'
import { Setting } from '@/model/Setting'
import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IRepresentationService } from './IRepresentationService'

export class RepresentationService extends BaseService implements IRepresentationService {
    public constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    getAllRepresentations(): Promise<IRepresentation[]> {
        throw new Error('Method not implemented.')
    }

    updateLegendFromSettings(settings: Setting): void {
        ReturnPeriod.legend = settings.getLegend('rt')
        FloodRisk.legend = settings.getLegend('flood_risk')
    }
}
