export class EventDTO {
    constructor(
        readonly cityName: string,
        readonly startDate: string,
        readonly endDate: string,
        readonly maxTTime: string,
        readonly maxTGeojson: string,
        readonly maxT: number,
        readonly max1dayAvg: number,
        readonly max1hourAvg: number,
        readonly areaT5Plus: number
    ) {}
}
