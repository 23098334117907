import { DateTime } from 'luxon'

export class Statistic {
    constructor(
        private timestamp: DateTime,
        private maxT: number,
        private max1hourVol: number,
        private max1dayVol: number,
        private max1hourAvg: number,
        private max1dayAvg: number,
        private areaT5Plus: number
    ) {}

    getTimestamp(): DateTime {
        return this.timestamp
    }

    getMaxT(): number {
        return this.maxT
    }

    getMax1hourVol(): number {
        return this.max1hourVol
    }

    getMax1dayVol(): number {
        return this.max1dayVol
    }

    getMax1hourAvg(): number {
        return this.max1hourAvg
    }

    getMax1dayAvg(): number {
        return this.max1dayAvg
    }

    getAreaT5Plus(): number {
        return this.areaT5Plus
    }
}
