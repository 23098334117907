import { DateTime } from 'luxon'

import { Catchment } from './Catchment'
import { ITimeAndCityLocalizable } from './ITimeAndCityLocalizable'
import { FloodDepth, FloodRisk, IRepresentation, Rainfall, ReturnPeriod } from './Representation'
import { History, Reference, Forecast, Max } from './TimeStepTypes'

export class TimeStep {
    protected type: string | null = null

    constructor(
        readonly snapshot: ITimeAndCityLocalizable,
        readonly timestamp: DateTime,
        readonly administrativeArea: Array<number>,
        readonly catchments: Array<Catchment>,
        readonly maxFloodLevel: number,
        readonly maxIntensity: number,
        readonly maxRisk: number,
        readonly maxRt: number,
        readonly maxTa: number
    ) {
        this.timestamp = timestamp.setZone(this.snapshot.city.getTimezone())
    }

    get epochSeconds(): number {
        return this.timestamp.toSeconds()
    }

    /**
     * Return the amount of seconds between this timestamp and dt.
     * @return a number < 0 when this timestamp is before dt, == 0 when this timestep occured at dt and > 0 when this timestep occured after dt.
     *
     */
    secondsBetween(dt: DateTime | undefined | null): number {
        if (dt === undefined || dt === null) return Number.NaN
        return this.epochSeconds - dt.toSeconds()
    }

    timeStampAsString(fmt = 'yyyy-MM-dd HH:mm'): string {
        return this.timestamp.toFormat(fmt)
    }

    getValue(representation: IRepresentation, pickMaxTa = false): number {
        // if (pickMaxTa) {
        //     this.maxTa
        // }
        switch (representation) {
            case Rainfall:
                return this.maxIntensity
            case ReturnPeriod:
                return pickMaxTa ? this.maxTa : this.maxRt
            case FloodDepth:
                return this.maxFloodLevel
            case FloodRisk:
                return this.maxRisk
            default:
                return 0
        }
    }

    getAdministrativeAreaValueAt(index: number): number {
        return this.administrativeArea.length > index ? this.administrativeArea[index] : 0
    }

    private getType() {
        if (this.type !== null) {
            return this.type
        }

        const referenceTimeStep = this.snapshot.getReference()
        if (!referenceTimeStep) {
            throw Error('Reference time step must be set properly.')
        }

        if (this.timestamp > referenceTimeStep.timestamp) {
            this.type = Forecast
        } else if (this.timestamp < referenceTimeStep.timestamp) {
            this.type = History
        } else {
            this.type = Reference
        }

        return this.type
    }

    public isHistory(): boolean {
        return this.getType() === History
    }

    public isReference(): boolean {
        return this.getType() === Reference
    }

    public isForecast(): boolean {
        return this.getType() === Forecast
    }

    public isMax(): boolean {
        return this.getType() === Max
    }

    public isEmpty(): boolean {
        return (
            this.catchments.length === 0 &&
            this.administrativeArea.length === 0 &&
            this.maxRt <= 0 &&
            this.maxRisk <= 0 &&
            this.maxIntensity <= 0 &&
            this.maxFloodLevel <= 0
        )
    }
}
