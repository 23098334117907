import { Legend } from './Legend'
import { Threshold } from './Threshold'

export interface IRepresentation {
    id: string
    translationKey: string
    unitTranslationKey: string
    legend: Legend
    dynamicPropertyName: string
    requiresCatchments: boolean
    hasSingularForm: boolean

    setLegend(newLegend: Legend): void

    format(value: number): string
    colorMatching(value: number): string
}

export abstract class Representation implements IRepresentation {
    constructor(
        readonly id: string,
        readonly translationKey: string,
        readonly unitTranslationKey: string,
        public legend: Legend,
        readonly dynamicPropertyName: string,
        readonly requiresCatchments = false,
        readonly hasSingularForm = false
    ) {}

    setLegend(newLegend: Legend): void {
        if (this.legend === newLegend) {
            return
        }

        this.legend = newLegend
    }

    abstract format(value: number): string

    colorMatching(value: number): string {
        return this.legend.getThresholdMatching(value).color
    }
}

class RainfallRepresentation extends Representation {
    constructor() {
        super(
            'rainfall',
            'model.representation.rainfall',
            'model.representation.rainfall.unit',
            new Legend('model.representation.rainfall', [
                new Threshold(1, '#d7ebfc'),
                new Threshold(2, '#c3e2fc'),
                new Threshold(5, '#a6cff2'),
                new Threshold(10, '#78b3e7'),
                new Threshold(20, '#5aa0e0'),
                new Threshold(50, '#2b60bb'),
                new Threshold(100, '#012379'),
                new Threshold(200, '#001c44', '> 500')
            ]),
            'intensity'
        )
    }

    format(value: number): string {
        return `${value.toFixed(0)}`
    }
}

class ReturnPeriodRepresentation extends Representation {
    constructor() {
        super(
            'returnPeriod',
            'model.representation.return_period',
            'model.representation.return_period.unit',
            new Legend('model.representation.return_period', []),
            'rt',
            false,
            true
        )
    }

    format(value: number): string {
        if (value < 1 && value.toFixed(0) != '1') {
            return `${value.toFixed(1)}`
        } else if (value > 1000) {
            return `${'> 1000'}`
        } else {
            return `${value.toFixed(0)}`
        }
    }
}

class FloodRiskRepresentation extends Representation {
    constructor() {
        super(
            'floodRisk',
            'model.representation.flood_risk',
            'model.representation.flood_risk.unit',
            new Legend('model.representation.flood_risk', [], 'distributed'),
            'risk'
        )
    }

    format(value: number): string {
        if (value === undefined || value === null) {
            return ''
        }

        const labelledThreshold = this.legend.thresholds.filter((t) => t.getLabel() !== '')
        for (let i = labelledThreshold.length - 1; i >= 0; --i) {
            const threshold = labelledThreshold[i]
            if (value >= threshold.value) {
                return threshold.getLabel()
            }
        }
        return labelledThreshold[0].getLabel()
    }
}

class FloodDepthRepresentation extends Representation {
    constructor() {
        super(
            'floodDepth',
            'model.representation.flood_depth',
            'model.representation.flood_depth.unit',
            new Legend('model.representation.flood_depth', [
                new Threshold(0.05, '#ecf5fc'),
                new Threshold(0.1, '#d9ebfa'),
                new Threshold(0.2, '#aed2ef'),
                new Threshold(0.3, '#6fa8dc'),
                new Threshold(0.4, '#3d7ec8'),
                new Threshold(0.5, '#315aa0'),
                new Threshold(0.75, '#012379'),
                new Threshold(1, '#001c44')
            ]),
            'FloodRisk',
            true
        )
    }

    format(value: number): string {
        return `${value.toFixed(2)}`
    }
}

export const Rainfall = new RainfallRepresentation()
export const ReturnPeriod = new ReturnPeriodRepresentation()
export const FloodRisk = new FloodRiskRepresentation()
export const FloodDepth = new FloodDepthRepresentation()

export const allRepresentations: IRepresentation[] = [Rainfall, ReturnPeriod, FloodRisk, FloodDepth]
export const hiddenRepresentations: IRepresentation[] = [FloodRisk]
