import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { SnapshotDTO } from './SnapshotDTO'
import { SnapshotRaw } from './SnapshotRaw'
import { TimeStepDTOFactory } from './TimeStepDTOFactory'
import { TimeStepMaxDTOFactory } from './TimeStepMaxDTOFactory'
import { TimeStepMaxRaw } from './TimeStepMaxRaw'
import { TimeStepRaw } from './TimeStepRaw'

export class SnapshotDTOFactory {
    static fromRaw(raw: SnapshotRaw): SnapshotDTO {
        const city = raw.city
        const timestamp = raw.timestamp
        const history = raw.history
        const reference = raw.reference
        const forecasts = raw.forecasts
        const maxFrame = raw.frame_max

        const mandatoryFields = [city, timestamp, history, reference, forecasts, maxFrame]
        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(SnapshotDTOFactory.name, raw)
        }

        return new SnapshotDTO(
            city as string,
            timestamp as string,
            history.map(TimeStepDTOFactory.fromRaw),
            TimeStepDTOFactory.fromRaw(reference as TimeStepRaw),
            forecasts.map(TimeStepDTOFactory.fromRaw),
            TimeStepMaxDTOFactory.fromRaw(maxFrame as TimeStepMaxRaw)
        )
    }
}
