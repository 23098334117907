

















import { DateTime } from 'luxon'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Data, Layout, PlotData } from 'plotly.js'

import { City } from '@/model/City'
import { Statistic } from '@/model/Statistic'

import { ICityService } from '@/services/city/ICityService'
import { IStatisticService } from '@/services/statistics/IStatisticService'

import EventChart from './EventChart.vue'
import EventTimesteps from './EventTimesteps.vue'

@Component({
    components: { EventChart, EventTimesteps }
})
export default class EventStats extends Vue {
    @Prop({ type: String, required: true }) readonly cityId!: string
    @Prop({ type: String, required: true }) readonly start!: string
    @Prop({ type: String, required: true }) readonly end!: string

    private cityService: ICityService | null = null
    private statisticsService: IStatisticService | null = null

    private city!: City
    private rainfalls: Statistic[] | null = []
    private totalRainfalls: number | null = 6

    statistics: Data[] = []
    layout: Partial<Layout> = this.configureLayout()

    async created(): Promise<void> {
        this.cityService = this.$services.get<ICityService>('city')
        this.statisticsService = this.$services.get<IStatisticService>('statistics')
        this.initialize()
        this.loadRainfallStatistics()
    }

    async mounted(): Promise<void> {
        this.initialize()
    }

    async initialize(): Promise<void> {
        this.setCity(this.cityId)
    }

    setCity(cityId: string): void {
        let city = this.cityService?.getCityCollection().getByName(cityId)
        if (!city) {
            return
        }
        this.city = city
    }

    async loadRainfallStatistics(): Promise<void> {
        if (!this.city) {
            return
        }
        const statistics = await this.statisticsService?.getCityStatistics(
            this.city,
            1,
            Number.MAX_SAFE_INTEGER,
            true,
            DateTime.fromISO(this.start, { zone: 'UTC' }),
            DateTime.fromISO(this.end, { zone: 'UTC' })
        )

        if (!statistics) {
            return
        }

        this.rainfalls = statistics?.getStatistics()
        this.totalRainfalls = statistics?.getTotalStatistics()

        this.statistics = [
            {
                ...this.rainfallsToChartData('maxT'),
                name: `${this.$t('model.event.max_t')}`,
                line: {
                    color: '#FCAA67'
                },
                hovertemplate: '%{y:.0f} [year]'
            },
            {
                ...this.rainfallsToChartData('max1hourVol'),
                name: `${this.$t('model.event.rainfall_1_hour_vol')}`,
                yaxis: 'y2',
                line: {
                    color: '#B0413E'
                },
                hovertemplate: '%{y:.0f} [mm/h]'
            },
            {
                ...this.rainfallsToChartData('max1dayVol'),
                name: `${this.$t('model.event.rainfall_1_day_vol')}`,
                yaxis: 'y2',
                line: {
                    color: '#233D4D'
                },
                hovertemplate: '%{y:.0f} [mm/h]'
            },
            {
                ...this.rainfallsToChartData('areaT5Plus'),
                name: `${this.$t('model.event.area_t5_plus')}`,
                yaxis: 'y3',
                line: {
                    color: '#53DD6C'
                },
                hovertemplate: '%{y:.0f} [km²]'
            }
        ]
    }

    rainfallsToChartData(key: string): Partial<PlotData> {
        const x: string[] = []
        const y: number[] = []

        if (this.rainfalls) {
            this.rainfalls.forEach((rainfall: any) => {
                x.push(rainfall.getTimestamp().setZone(this.city.getTimezone()).toFormat('yyyy-MM-dd HH:mm'))
                y.push(rainfall[key])
            })
        }

        return { x, y }
    }

    configureLayout(): Partial<Layout> {
        return {
            autosize: false,
            height: 500,
            width: 1200,
            yaxis: {
                visible: false,
                title: 'Max T',
                titlefont: { color: '#FCAA67' },
                tickfont: { color: '#FCAA67' }
            },
            yaxis2: {
                visible: false,
                title: 'Rainfall',
                titlefont: { color: '#B0413E' },
                tickfont: { color: '#B0413E' },
                anchor: 'free',
                overlaying: 'y',
                side: 'left',
                position: 0.15
            },
            yaxis3: {
                visible: false,
                title: 'Area T5 Plus',
                titlefont: { color: '#53DD6C' },
                tickfont: { color: '#53DD6C' },
                anchor: 'x',
                overlaying: 'y',
                side: 'right'
            },
            legend: {
                x: 0.5,
                y: 1.1,
                xanchor: 'center',
                orientation: 'h'
            },
            hovermode: 'x'
        }
    }

    @Watch('cityId')
    onCityChanged(cityId: string): void {
        this.setCity(cityId)
        this.loadRainfallStatistics()
    }
}
