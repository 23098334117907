import { DateTime } from 'luxon'

import { MeasureKind } from '@/model/MeasureKind'
import { Station } from '@/model/Station'
import { TimeSeries } from '@/model/TimeSeries'
import { TimeSeriesDTO } from '@/api/stations/dto/TimeSeriesDTO'
import { MeasureDTO } from '@/api/stations/dto/MeasureDTO'
import { MeasureCollection } from '@/model/MeasureCollection'

export class TimeSeriesFactory {
    static fromDTO(station: Station, dto: TimeSeriesDTO): TimeSeries {
        const measureKind = new MeasureKind(dto.measure_kind_name, 'mTAW')

        return new TimeSeries(
            dto.ts_id,
            station,
            measureKind,
            TimeSeriesFactory.mapToRow(measureKind, dto.history),
            TimeSeriesFactory.mapToRow(measureKind, dto.forecasts)
        )
    }

    static mapToRow(measureKind: MeasureKind, data: MeasureDTO[]): MeasureCollection {
        return new MeasureCollection(
            data.map((m) => [DateTime.fromISO(m.timestamp).toUTC(), m.value]),
            measureKind
        )
    }
}
