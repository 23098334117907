export class StatisticDTO {
    constructor(
        readonly timestamp: string,
        readonly maxT: number,
        readonly max1dayVol: number,
        readonly max1hourVol: number,
        readonly max1dayAvg: number,
        readonly max1hourAvg: number,
        readonly areaT5Plus: number
    ) {}
}
