import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { TranslationAPI } from '@/api/translation/TranslationAPI'

import { BaseService } from '../BaseService'
import { ITranslationService } from './ITranslationService'
import { Disclaimer } from '@/model/Disclaimer'
import { TranslationFactory } from '../translation/TranslationFactory'

Vue.use(VueI18n)

// TODO: extract to global config?
const DEFAULT_LANGUAGE = 'en'

/* eslint-disable @typescript-eslint/no-explicit-any */
export class TranslationService extends BaseService implements ITranslationService {
    private readonly api: TranslationAPI = new TranslationAPI()
    protected readonly i18n: VueI18n = new VueI18n({
        fallbackLocale: DEFAULT_LANGUAGE
    })
    protected languages: Map<string, any> = new Map()
    protected disclaimers: Map<string, any> = new Map()

    public getVuePlugin(): any {
        return this.i18n
    }

    public async useLanguage(code: string): Promise<void> {
        if (this.i18n.locale === code) {
            return Promise.resolve()
        }

        if (this.languages.has(code)) {
            this.configure(code)
            return Promise.resolve()
        }

        const languages = await this.api.fetchLanguage(code)
        this.languages.set(code, languages)
        this.configure(code)
    }

    public async getAvailableLanguages(): Promise<Map<string, string>> {
        return await this.api.getAvailableLanguages()
    }

    protected configure(code: string): void {
        this.i18n.setLocaleMessage(code, this.languages.get(code))
        this.i18n.locale = code
        document.querySelector('html')?.setAttribute('lang', code)
    }

    public translate(key: string): string {
        return this.i18n.t(key).toString()
    }

    public async getDislaimer(code: string): Promise<Disclaimer> {
        if (this.disclaimers.has(code)) {
            return this.disclaimers.get(code)
        }

        const dto = await this.api.getDisclaimer(code)
        const disclaimer = TranslationFactory.fromDisclaimerDTO(dto)
        this.disclaimers.set(code, disclaimer)
        return disclaimer
    }
}
