import { DateTime } from 'luxon'

import { TimeStepDTO } from '@/api/core/dto/snapshot/TimeStepDTO'
import { TimeStep } from '@/model/TimeStep'
import { numberOr } from '@/utils/converters'

import { CatchmentFactory } from './CatchmentFactory'
import { ITimeAndCityLocalizable } from '@/model/ITimeAndCityLocalizable'

export class TimeStepFactory {
    static createEmpty(snapshot: ITimeAndCityLocalizable, timestamp: DateTime): TimeStep {
        return new TimeStep(snapshot, timestamp, [], [], 0, 0, 0, 0, 0)
    }

    static fromDTO(dto: TimeStepDTO, snapshot: ITimeAndCityLocalizable): TimeStep {
        return new TimeStep(
            snapshot,
            DateTime.fromISO(dto.timestamp, { zone: 'UTC' }),
            dto.administrativeAreas,
            dto.catchments.map(CatchmentFactory.fromDTO),
            numberOr(dto.maxFloodLevel),
            numberOr(dto.maxIntensity),
            numberOr(dto.maxRisk),
            numberOr(dto.maxRt),
            numberOr(dto.maxTa)
        )
    }
}
