
















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { ContextFactory } from '@/views/secure/overview/ContextFactory'

import { FloodDepth, IRepresentation } from '@/model/Representation'
import { TimeStepSequence } from '@/model/TimeStepSequence'
import { DateTime } from 'luxon'
import { TimeStep } from '@/model/TimeStep'

const TranslationKeyRegEx = /^[A-Za-z\\._]+$/

@Component({
    components: {}
})
export default class Heatline extends Vue {
    @Prop() timeSteps!: Iterable<TimeStep>
    @Prop() representation!: IRepresentation
    @Prop() start!: DateTime
    @Prop() end!: DateTime

    @Ref('cursor') cursor!: HTMLElement
    @Ref('heat-line-canvas') canvas!: HTMLCanvasElement

    private timeStepsSequence: TimeStepSequence | null = null
    private tooltipText = ''
    private tooltipActive = false
    private cursorLeft = 0

    created(): void {
        this.timeStepsSequence = TimeStepSequence.fromIterable(this.timeSteps, this.start, this.end)
    }

    mounted(): void {
        this.redraw(ContextFactory.renderingContextFor(this.canvas))
    }

    @Watch('timeSteps')
    onHistoryChanged(): void {
        this.timeStepsSequence = TimeStepSequence.fromIterable(this.timeSteps, this.start, this.end)
        this.redraw(ContextFactory.renderingContextFor(this.canvas))
    }

    @Watch('representation')
    onRepresentationChanged(): void {
        this.redraw(ContextFactory.renderingContextFor(this.canvas))
    }

    private redraw(ctx: CanvasRenderingContext2D): void {
        if (!this.timeStepsSequence) return
        if (!this.timeStepsSequence.hasTimeSteps) {
            console.warn('heatline timestepsequence has no time steps')
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            return
        }

        const gradient = ctx.createLinearGradient(0, 0, this.canvas.width, 0)
        const [fr, lr] = [this.timeStepsSequence.firstRelative, this.timeStepsSequence.lastRelative]
        if (fr > 0) {
            gradient.addColorStop(fr, 'transparent')
        }
        for (const [n, ts] of this.timeStepsSequence.entries) {
            const val = ts.getValue(this.representation, true)
            gradient.addColorStop(n, this.representation.colorMatching(val))
        }
        if (lr < 1) {
            gradient.addColorStop(lr, 'transparent')
        }
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }

    onCanvasEnter(): void {
        this.tooltipActive =
            this.timeStepsSequence !== null &&
            this.timeStepsSequence.hasTimeSteps &&
            this.representation !== null &&
            this.representation.id !== FloodDepth.id
    }

    onCanvasLeave(): void {
        this.tooltipActive = false
    }

    onMove(evt: MouseEvent): void {
        if (!this.timeStepsSequence) {
            return
        }

        this.cursor.style.left = evt.offsetX + 'px'

        try {
            const closest = this.mouseEventToTickIndex(evt)
            const ts = this.timeStepsSequence.getFromRelative(closest)
            const tsVal = ts?.getValue(this.representation, true) || NaN

            if (!isNaN(tsVal)) {
                this.tooltipText = this.formatValue(tsVal)
            }
        } catch (e) {
            this.tooltipText = this.$t('components.timeline.no_data').toString()
        }
    }

    private formatValue(value: number): string {
        const representationName = this.$t(this.representation.translationKey).toString()
        const formattedValue = this.representation.format(value)

        let translatedValue = formattedValue
        let unit = this.representation.unitTranslationKey

        if (TranslationKeyRegEx.test(formattedValue)) {
            translatedValue = this.$t(formattedValue) as string
            unit = ''
        }

        if (parseInt(formattedValue) < 2 && this.representation.hasSingularForm) {
            unit = this.representation.unitTranslationKey + '.singular'
        }

        unit = this.$t(unit) as string
        if (!unit || translatedValue.includes(unit)) {
            unit = ''
        }

        return `${representationName}: ${translatedValue} ${unit}`
    }

    private mouseEventToTickIndex(evt: MouseEvent): number {
        return Math.max(0, Math.min(1, evt.offsetX / this.canvas.offsetWidth))
    }
}
