























































































































































































































import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { Event } from '@/model/Event'

import { ICityService } from '@/services/city/ICityService'
import { IEventService } from '@/services/event/IEventService'
import { IUserService } from '@/services/user/IUserService'
import { showToast } from '@/utils/toast'

@Component({
    components: {}
})
export default class EventList extends Vue {
    @Prop({ type: String, required: true }) readonly cityId!: string

    private cityService: ICityService | null = null
    private eventService: IEventService | null = null
    private userService: IUserService | null = null

    private city: City | null = null
    private events: Event[] | null = []
    private totalEvents: number | null = 0

    private isLoading = false
    private noEvents = true
    private allEventsOnPage = false
    private eventsPerPage = 5
    private currentPage = 1
    private sortDirection = 'asc'
    private yearSelection = DateTime.now().year

    async created(): Promise<void> {
        this.cityService = this.$services.get<ICityService>('city')
        this.eventService = this.$services.get<IEventService>('event')
        this.userService = this.$services.get<IUserService>('user')
        this.initialize()
    }

    async mounted(): Promise<void> {
        this.loadEvents()
    }

    async initialize(): Promise<void> {
        this.setCity(this.cityId)
        this.setYearSelection()
    }

    setCity(cityId: string): void {
        let city = this.cityService?.getCityCollection().getByName(cityId)
        if (!city) {
            return
        }
        this.city = city
    }

    setYearSelection(): void {
        let yearSelection = this.userService?.getLastSelectedEventYear()
        if (!yearSelection) {
            return
        }
        this.yearSelection = yearSelection
    }

    async loadEvents(): Promise<void> {
        this.isLoading = true
        if (!this.city) {
            this.noEvents = true
            this.isLoading = false
            return
        }
        const sortDirectionRequest = this.sortDirection === 'asc' ? true : false
        const events = await this.eventService?.getRainEvents(
            this.city,
            this.yearSelection,
            this.currentPage,
            this.eventsPerPage,
            sortDirectionRequest
        )

        if (!events) {
            this.noEvents = true
            this.isLoading = false
            return
        }

        this.events = events.getEvents()
        this.totalEvents = events.getTotalEvents()
        this.noEvents = this.totalEvents == 0 ? true : false

        this.eventsPerPage = this.resetNumberOfEventsPerPage()
        this.isLoading = false
    }

    async replayAlarms(event: Event): Promise<void> {
        if (!this.city) {
            return
        }
        await this.eventService
            ?.replayRainEvents(this.city, event.getStartDate())
            .then(() => showToast('is-success', 3000, `${this.$t('components.events_table.actions.replay.success')}`))
            .catch(() => showToast('is-danger', 3000, `${this.$t('components.events_table.actions.replay.failure')}`))
    }

    saveSortDirection(column: string, sort: string): void {
        /**
         * Currently, the only column that is sorted is the start date.
         */
        switch (column) {
            case 'start':
                this.sortDirection = sort
                break
            default:
                break
        }
    }

    resetNumberOfEventsPerPage(): number {
        return this.allEventsOnPage && this.noEvents ? 10 : this.eventsPerPage
    }

    dateToParam(date: DateTime): string {
        return date.toISO()
    }

    get eventsPerPageOptions(): number[] {
        const eventsPerPageOptions: number[] = []
        for (let i = 5; i <= 20; i += 5) {
            if (this.totalEvents && i < this.totalEvents) {
                eventsPerPageOptions.push(i)
            }
        }
        if (this.totalEvents) {
            eventsPerPageOptions.push(this.totalEvents) // all events option
        }
        this.eventsPerPage = eventsPerPageOptions[0]
        return eventsPerPageOptions
    }

    get years(): number[] {
        const currentYear = DateTime.now().year
        const years: number[] = []
        for (let i = currentYear - 4; i <= currentYear; i++) {
            years.push(i)
        }
        return years
    }

    @Watch('cityId')
    onCityChanged(cityId: string): void {
        this.setCity(cityId)
        this.currentPage = 1
        this.loadEvents()
    }

    @Watch('yearSelection')
    onYearSelectionChanged(year: number | null): void {
        if (!year) {
            return
        }
        this.userService?.setLastSelectedEventYear(year)
        this.yearSelection = year
        this.currentPage = 1
        this.loadEvents()
    }

    @Watch('eventsPerPage')
    onEventsPerPageChanged(): void {
        this.allEventsOnPage = this.eventsPerPage === this.totalEvents ? true : false
        this.currentPage = 1
        this.loadEvents()
    }

    @Watch('currentPage')
    onPageChanged(): void {
        this.loadEvents()
    }

    @Watch('sortDirection')
    onSortDirectionChanged(): void {
        this.currentPage = 1
        this.loadEvents()
    }
}
