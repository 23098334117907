import { StatisticRaw } from './StatisticRaw'

export class StatisticsRaw {
    timestamp: string
    area_T5_plus: number
    maxT: number
    rainfall_1day_vol_max: number
    rainfall_1hour_vol_max: number
    rainfall_1hour_avg: number
    rainfall_1day_avg: number

    constructor(
        timestamp: string,
        areaT5Plus: number,
        maxT: number,
        rainfall1dayAvg: number,
        rainfall1hourAvg: number,
        rainfall1hourVol: number,
        rainfall1dayVol: number
    ) {
        this.timestamp = timestamp
        this.area_T5_plus = areaT5Plus
        this.maxT = maxT
        this.rainfall_1day_vol_max = rainfall1hourVol
        this.rainfall_1hour_vol_max = rainfall1dayVol
        this.rainfall_1hour_avg = rainfall1hourAvg
        this.rainfall_1day_avg = rainfall1dayAvg
    }

    static fromRaw(raw: StatisticRaw): StatisticsRaw {
        return new StatisticsRaw(
            raw.timestamp,
            raw.stats.area_T5_plus,
            raw.stats.maxT,
            raw.stats.rainfall_1day_vol_max,
            raw.stats.rainfall_1hour_vol_max,
            raw.stats.rainfall_1hour_avg,
            raw.stats.rainfall_1day_avg
        )
    }
}
