import { MapBoxGL } from '../MapBoxGL'

import { IStationService, StationSelectionEventArgs } from '@/services/station/IStationService'
import { ServiceLocator } from '@/services/ServiceLocator'

import { AnyLayer, EventData, MapLayerMouseEvent, MapLayerTouchEvent, SymbolLayer } from 'mapbox-gl'

import { Station } from '@/model/Station'
import { City } from '@/model/City'
import { BaseController } from './BaseController'
import { IController } from './IController'
import { IControllerParent } from './IControllerParent'
import { IMapService } from '@/services/map/IMapService'
import { TimeStep } from '@/model/TimeStep'

const DEFAULT_SOURCE = 'all-stations'
const UNSELECTED_SOURCE = 'unselected-stations'
const SELECTED_SOURCE = 'selected-stations'

/**
 * Handle user interactions related to stations markers
 */
export class StationController extends BaseController implements IController {
    private stationService: IStationService
    private unselectedStations: Map<string, Station> = new Map()
    private selectedStations: Map<string, Station> = new Map()

    private city: City | null = null
    private handler = async (evt: StationSelectionEventArgs) => await this.updateFeatureState(evt)

    constructor(
        parent: IControllerParent,
        mapBoxGL: MapBoxGL,
        mapService: IMapService,
        serviceLocator: ServiceLocator
    ) {
        super(parent, mapBoxGL, mapService)
        this.stationService = serviceLocator.get<IStationService>('stations')
        this.stationService.stationSelectionChanged.subscribe(this.handler)
    }

    beforeDestroy(): void {
        this.stationService.stationSelectionChanged.unsubscribe(this.handler)
    }

    async load(city: City, timeStep?: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        if (!timeStep) {
            return false
        }
        if (this.city == null || this.city.getName() !== timeStep.snapshot.city.getName()) {
            this.city = timeStep.snapshot.city
            await this.reloadStations()
        }
        return true
    }

    private async reloadStations(): Promise<void> {
        if (this.city == null) return
        this.unselectedStations.clear()
        this.selectedStations.clear()

        const stations = await this.stationService.getStationsWithinCircle(this.city.getCoordinates(), 1)
        stations.forEach((s) => this.unselectedStations.set(s.no, s))

        this.mapBoxGL.updateSource(
            UNSELECTED_SOURCE,
            this.stationService.mapToFeatureCollection([...this.unselectedStations.values()])
        )
        this.mapBoxGL.updateSource(
            SELECTED_SOURCE,
            this.stationService.mapToFeatureCollection([...this.selectedStations.values()])
        )
        this.mapBoxGL.addLayerEventHandler(
            'click',
            `${this.mapBoxGL.applicationPrefix}-unselectedstations`,
            async (evt) => this.onMarkerClicked(evt)
        )

        this.mapBoxGL.addLayerEventHandler(
            'click',
            `${this.mapBoxGL.applicationPrefix}-selectedstations`,
            async (evt) => this.onMarkerClicked(evt)
        )
    }

    async onMarkerClicked(e: (MapLayerMouseEvent | MapLayerTouchEvent) & EventData): Promise<void> {
        if (e.features && e.features[0] && e.features[0].properties) {
            const featureProperties = e.features[0].properties
            const no = featureProperties['no']
            const stationSource = this.unselectedStations.has(no) ? this.unselectedStations : this.selectedStations

            await this.stationService.toggleStation(stationSource.get(no) as Station)
        }
    }

    async updateFeatureState(args: StationSelectionEventArgs): Promise<void> {
        const isAddAction = args.action === 'add' ? true : false

        if (isAddAction) {
            args.stations.forEach((s) => this.selectedStations.set(s.no, s))
            args.stations.forEach((s) => this.unselectedStations.delete(s.no))
        } else {
            args.stations.forEach((s) => this.selectedStations.delete(s.no))
            args.stations.forEach((s) => this.unselectedStations.set(s.no, s))
        }

        this.mapBoxGL.updateSource(
            UNSELECTED_SOURCE,
            this.stationService.mapToFeatureCollection([...this.unselectedStations.values()])
        )
        this.mapBoxGL.updateSource(
            SELECTED_SOURCE,
            this.stationService.mapToFeatureCollection([...this.selectedStations.values()])
        )
        this.mapBoxGL.updateSource(
            DEFAULT_SOURCE,
            this.stationService.mapToFeatureCollection([
                ...this.selectedStations.values(),
                ...this.unselectedStations.values()
            ])
        )
    }

    clearCities(): void {
        this.stationService.clearStations()
    }

    getLayers(): AnyLayer[] {
        return [
            {
                id: `${this.mapBoxGL.applicationPrefix}-unselectedstations`,
                type: 'symbol',
                source: UNSELECTED_SOURCE,
                minzoom: 3,
                layout: {
                    'icon-image': 'unselected_marker',
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-ignore-placement': true,
                    'text-size': 0
                },
                paint: {
                    'icon-color': '#f14668'
                }
            } as SymbolLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-selectedstations`,
                type: 'symbol',
                source: SELECTED_SOURCE,
                minzoom: 3,
                layout: {
                    'icon-image': 'selected_marker',
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-ignore-placement': true,
                    'text-size': 0
                },
                paint: {
                    'icon-color': '#f14668'
                }
            } as SymbolLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-stationstext`,
                type: 'symbol',
                source: DEFAULT_SOURCE,
                minzoom: 3,
                layout: {
                    'text-field': ['get', 'name_no'],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-offset': [0, 1.5],
                    'text-anchor': 'top',
                    'text-size': 10,
                    'icon-size': 0.01,
                    'icon-allow-overlap': false,
                    'text-allow-overlap': false,
                    'icon-ignore-placement': false,
                    'text-ignore-placement': false
                },
                paint: {
                    'text-halo-color': '#ffffff',
                    'text-halo-blur': 1,
                    'text-halo-width': 2
                }
            } as SymbolLayer
        ]
    }

    getSources(): string[] {
        return [UNSELECTED_SOURCE, SELECTED_SOURCE, DEFAULT_SOURCE]
    }

    getCursors(): Map<string, string> {
        return new Map([
            [`${this.mapBoxGL.applicationPrefix}-selectedstations`, 'pointer'],
            [`${this.mapBoxGL.applicationPrefix}-unselectedstations`, 'pointer'],
            [`${this.mapBoxGL.applicationPrefix}-stationstext`, 'pointer']
        ])
    }

    getSymbols(): Map<string, { url: string; sdf: boolean }> {
        return new Map([
            [
                'unselected_marker',
                {
                    url: require('@/assets/marker-red.png'),
                    sdf: false
                }
            ],
            [
                'selected_marker',
                {
                    url: require('@/assets/marker-purple.png'),
                    sdf: false
                }
            ]
        ])
    }
}
