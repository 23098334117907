import { Threshold } from './Threshold'

export class Legend {
    constructor(
        readonly titleTranslationKey: string,
        readonly thresholds: Threshold[],
        readonly mode: 'linear' | 'distributed' = 'linear'
    ) {}

    get thresholdCount(): number {
        return this.thresholds.length
    }

    get maxThreshold(): number {
        return this.thresholds[this.thresholdCount - 1].value
    }

    eachThreshold(action: (t: Threshold, index: number) => void): void {
        this.thresholds.forEach(action)
    }

    getThresholdMatching(value: number): Threshold {
        for (let i = this.thresholdCount - 1; i >= 0; --i) {
            const threshold = this.thresholds[i]
            if (value >= threshold.value) {
                return threshold
            }
        }
        return this.thresholds[0]
    }
}
