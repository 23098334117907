import { SettingDTO } from '@/api/setting/dto/SettingDTO'
import { Legend } from '@/model/Legend'

import { Setting } from '@/model/Setting'
import { LegendFactory } from './LegendFactory'

export class SettingFactory {
    static fromSettingDTO(dto: SettingDTO): Setting {
        return new Setting(
            dto.defaultLanguage,
            dto.cellSize,
            new Map<string, Legend>([
                ['rt', LegendFactory.rtLegendFromDTO(dto.legends)],
                ['flood_risk', LegendFactory.floodRiskLegendFromDTO(dto.legends)]
            ])
        )
    }
}
