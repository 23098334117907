import { DateTime } from 'luxon'

import { StatisticDTO } from '@/api/event/dto/statistics/StatisticDTO'

import { Statistic } from '@/model/Statistic'

export class StatisticFactory {
    static fromDTO(dto: StatisticDTO): Statistic {
        return new Statistic(
            DateTime.fromISO(dto.timestamp, { zone: 'UTC' }),
            dto.maxT,
            dto.max1hourVol,
            dto.max1dayVol,
            dto.max1hourAvg,
            dto.max1dayAvg,
            dto.areaT5Plus
        )
    }
}
