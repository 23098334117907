let baseURL = process.env.VUE_APP_BASE_API_URL
if (process.env.NODE_ENV !== 'development') {
    baseURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
}

export function makeURL(path: string): string {
    let fullPath = path
    if (!path.startsWith('/')) {
        fullPath = `${path}/`
    }
    return `${baseURL}${fullPath}`
}
