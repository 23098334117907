import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { RtLegendDTOFactory } from './RtLegendDTOFactory'
import { SettingDTO } from './SettingDTO'
import { SettingRaw } from './SettingRaw'

export class SettingDTOFactory {
    static fromRaw(settingRaw: SettingRaw): SettingDTO {
        const mandatoryFields: any[] = [settingRaw.default_language, settingRaw.cell_size, settingRaw.rt_legend]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(SettingDTOFactory.name, settingRaw)
        }

        return new SettingDTO(
            settingRaw.default_language,
            settingRaw.cell_size,
            RtLegendDTOFactory.fromRaw(settingRaw.rt_legend)
        )
    }
}
