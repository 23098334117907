import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Buefy from 'buefy'

import ServiceLocatorPlugin from './plugins/services'
import AclPlugin from './plugins/acl'

import { serviceLocatorInstance } from './services/ServiceLocator'
import { ITranslationService } from './services/translation/ITranslationService'

Vue.config.productionTip = false

// 3rd-party plugins
Vue.use(Buefy)

// Local plugins
Vue.use(ServiceLocatorPlugin)
Vue.use(AclPlugin)

new Vue({
    router,
    i18n: serviceLocatorInstance.get<ITranslationService>('translation').getVuePlugin(),
    render: (h) => h(App)
}).$mount('#app')
