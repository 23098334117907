import { DefaultCredentials } from '@/api/auth/model/DefaultCredentials'
import { SimpleAuthAPI } from '@/api/auth/SimpleAuthAPI'
import { EventEmitter } from '@/model/EventEmitter'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IAuthService, UserAuthEventArgs } from './IAuthService'

export class AuthService extends BaseService implements IAuthService {
    private api: SimpleAuthAPI = new SimpleAuthAPI()

    readonly loggedIn = new EventEmitter<UserAuthEventArgs>()
    readonly loggedOut = new EventEmitter<UserAuthEventArgs>()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
        this.api?.initialize()
    }

    async login(username: string, password: string): Promise<boolean> {
        const loggedIn = await this.api.login(new DefaultCredentials(username, password))
        if (loggedIn) {
            this.loggedIn.notify(new UserAuthEventArgs())
        }
        return loggedIn
    }

    isAuthenticated(): boolean {
        return this.api.isAuthenticated()
    }

    logout(): void {
        this.api.logout().then(() => this.loggedOut.notify(new UserAuthEventArgs()))
    }
}
