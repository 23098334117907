import { CatchmentDTO } from './CatchmentDTO'

export class TimeStepDTO {
    constructor(
        readonly timestamp: string,
        readonly administrativeAreas: Array<number>,
        readonly catchments: Array<CatchmentDTO>,
        readonly maxFloodLevel: number,
        readonly maxIntensity: number,
        readonly maxRisk: number,
        readonly maxRt: number,
        readonly maxTa: number
    ) {}
}
