import { RtLegendDTO } from '@/api/setting/dto/RtLegendDTO'
import { Legend } from '@/model/Legend'
import { Threshold } from '@/model/Threshold'

/**
 * Make model.Legend from Legend DTOs object to integrate with Return Period and Flood Risk reprsentations.
 */
export class LegendFactory {
    static rtLegendFromDTO(dto: RtLegendDTO): Legend {
        const thresholds: Array<Threshold> = []

        for (const entry of dto.returnPeriod.keys()) {
            thresholds.push(
                new Threshold(entry, dto.thresholdColor.get(entry) || '#ffffff', dto.returnPeriod.get(entry) || '')
            )
        }

        return new Legend(
            'model.representation.return_period',
            thresholds.sort((a, b) => a.value - b.value),
            'linear'
        )
    }

    static floodRiskLegendFromDTO(dto: RtLegendDTO): Legend {
        const thresholds: Array<Threshold> = []

        for (const entry of dto.thresholdColor.keys()) {
            thresholds.push(
                new Threshold(entry, dto.thresholdColor.get(entry) || '#ffffff', dto.floodRisk.get(entry) || '')
            )
        }

        return new Legend(
            'model.representation.flood_risk',
            thresholds.sort((a, b) => a.value - b.value),
            'linear'
        )
    }
}
