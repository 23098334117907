






























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { IAuthService } from '@/services/auth/IAuthService'
import { IUserService } from '@/services/user/IUserService'
import { ITranslationService } from '@/services/translation/ITranslationService'

const enum AuthErrors {
    BadCredentials = 'dialogs.auth.errors.bad_credentials',
    ConnectionIssue = 'dialogs.auth.errors.connection_issue'
}

@Component
export default class AuthView extends Vue {
    @Prop({ type: [Object, String] }) readonly to?: RawLocation

    username = ''
    password = ''
    error: AuthErrors | null = null

    async login(): Promise<void> {
        const userService = this.$services.get<IUserService>('user')
        const authService = this.$services.get<IAuthService>('auth')

        try {
            const authenticated = await authService.login(this.username, this.password)
            if (authenticated) {
                let currentUser = await userService.getCurrentUser()
                if (currentUser.language) {
                    this.useLanguage(currentUser.language)
                }
                this.$router.push(this.to ? this.to : { name: 'Home' })
            } else {
                this.error = AuthErrors.BadCredentials
            }
        } catch (error) {
            this.error = AuthErrors.ConnectionIssue
            console.log(error)
        }
    }

    async useLanguage(code: string): Promise<void> {
        const translationService = this.$services.get<ITranslationService>('translation')
        translationService.useLanguage(code)
    }

    redirectUser(view: string): void {
        this.$router.push({ name: view })
    }

    get canSubmit(): boolean {
        return this.username.trim() !== '' && this.password.trim() !== ''
    }
}
