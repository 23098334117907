import { Catchment } from './Catchment'
import { ITimeAndCityLocalizable } from './ITimeAndCityLocalizable'
import { TimeStep } from './TimeStep'
import { Max } from './TimeStepTypes'

export class TimeStepMax extends TimeStep {
    constructor(snapshot: ITimeAndCityLocalizable, administrativeArea: Array<number>, catchments: Array<Catchment>) {
        super(snapshot, snapshot.timestamp, administrativeArea, catchments, 0, 0, 0, 0, 0)
        this.type = Max
    }
}
