import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { RtLegendDTO } from './RtLegendDTO'
import { KeyValMap, RtLegendRaw } from './SettingRaw'

export class RtLegendDTOFactory {
    static fromRaw(raw: RtLegendRaw): RtLegendDTO {
        const thresholdColor = raw.threshold_colour
        const returnPeriod = raw.return_period
        const floodRisk = raw.flood_risk

        const mandatoryFields = [thresholdColor, returnPeriod, floodRisk]
        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError('return_period_and_flood_risk', raw)
        }

        return new RtLegendDTO(
            map2NumberString(thresholdColor),
            map2NumberString(returnPeriod),
            map2NumberString(floodRisk)
        )
    }
}

function map2NumberString(map: KeyValMap | null): Map<number, string> {
    const converted = new Map<number, string>()

    if (map !== null) {
        for (const key in map) {
            const asNumber = Number(key)
            if (isNaN(asNumber)) {
                throw new InvalidDTOError('invalid_numeric_key', map)
            } else {
                converted.set(asNumber, map[key] || '')
            }
        }
    }

    return converted
}
